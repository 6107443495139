import styled from '@emotion/styled'
import { Playlist } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  label?: string
  URL?: string
}

export const Spotify = memo(function Spotify({ label, URL }: Props) {
  if (!label || !URL) {
    return null
  }

  return (
    <Anchor href={URL} rel="noopener" target="_blank">
      <SVG dial={5} inline>
        <Playlist />
      </SVG>
      <Label>{label}</Label>
    </Anchor>
  )
})

const Anchor = styled.a`
  min-width: 6.125rem;
  cursor: pointer;
  position: absolute;
  top: 47.1vh;
  left: 4.167vw;
  text-align: center;
  z-index: 4;
  &:hover {
    > div {
      &:before {
        width: 100%;
        height: 100%;
      }
    }

    svg {
      transform: rotate(90deg);
    }
  }

  @media (max-width: 1023px) {
    min-width: auto;
    top: 11.1875rem;
    left: auto;
    right: 1.9375rem;
  }
`

const SVG = styled(FlexBox)`
  width: 4.6875rem;
  height: 4.6875rem;
  position: relative;
  &:before {
    content: '';
    width: 4.375rem;
    height: 4.375rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight2};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out;
  }

  svg {
    width: auto;
    height: 2.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: relative;
    transition: 0.3s ease-in-out;
  }
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.0406rem;
  line-height: 1rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    display: none;
  }
`

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  title?: string
}

export const SimpleBox = memo(function SimpleBox({
  cta,
  description,
  image,
  title,
}: Props) {
  if (!description && !title) {
    return null
  }

  return (
    <Container>
      <Wrapper row stretch wrap>
        {image ? (
          <LeftSide>
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          </LeftSide>
        ) : null}

        <RightSide dial={4}>
          {title ? <Title>{title}</Title> : null}

          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}

          {cta ? <CTA variant="outline" {...cta} /> : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.75rem 0 12.5rem;
  padding: 0 11.944vw;

  @media (max-width: 1023px) {
    padding: 0 2rem;
  }

  @media (max-width: 991px) {
    margin: 10rem 0 6.25rem;
  }

  @media (max-width: 574px) {
    margin: 7.5rem 0 6.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  width: calc(50% - 9.097vw);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding: 5.625rem 9.097vw;
  flex: 1;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 4rem 2.6875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.875rem;

  @media (max-width: 991px) {
    max-width: 37.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.6875rem;
`

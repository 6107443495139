import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

export interface Props {
  className?: string
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  isLoaded?: boolean
  style?: any
  title: string
}

export const Service = memo(function Service({
  className,
  cta,
  description,
  image,
  isLoaded,
  style,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container className={className}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Inner style={style}>
          <Image
            animationStart={isLoaded ? true : false}
            media="(min-width: 2800px)"
            {...(isLoaded ? image : null)}
          />

          <Spinner variant="dark" />
        </Inner>

        <Wrapper style={style}>
          <Title>{title}</Title>
          <Media greaterThanOrEqual="ipadVertical">
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            {cta ? <Button variant="simple" {...cta} /> : null}
          </Media>
          <Media lessThan="ipadVertical">
            {cta ? <Button variant="outline" {...cta} /> : null}
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  overflow: hidden;
  position: relative;
  text-align: left;

  @media (max-width: 767px) {
    overflow: visible;
    margin-bottom: 2.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Inner = styled.div`
  width: 100%;
  height: calc(100% - 6.25rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    position: relative;
    height: 72.5vw;
  }
`

const Wrapper = styled.div`
  max-width: 35rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem 4rem 3.375rem;
  position: absolute;
  bottom: 0;
  left: 11.944vw;
  z-index: 2;

  @media (max-width: 991px) {
    left: 2rem;
  }

  @media (max-width: 767px) {
    position: relative;
    width: calc(100% - 3.875rem);
    padding: 2.5rem;
    max-width: none;
    margin-top: -2.5rem;
    text-align: center;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.1875rem;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.75rem;
`

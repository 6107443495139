import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as ServiceProps, Service } from './Service'

const AUTOPLAY_TIMER = 5000

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const ServicesSlider = memo(function ServicesSlider({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 2000,
    },
    drag: false,
    loop: services.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      services.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Slider>
            {services.map((item, index) => (
              <Service
                key={index}
                {...item}
                isLoaded={loaded[index] || nextLoaded[index]}
              />
            ))}
          </Slider>
        </Media>
        <Media greaterThanOrEqual="ipadVertical">
          <Slider ref={sliderRef}>
            {services.map((item, index) => (
              <Service
                className="keen-slider__slide"
                key={index}
                isLoaded={loaded[index] || nextLoaded[index]}
                style={positionStyle(index)}
                {...item}
              />
            ))}
          </Slider>

          {services.length > 1 ? (
            <Arrows row>
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
              <Arrow
                direction="R"
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </Arrows>
          ) : null}
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  position: relative;
  text-align: center;

  @media (max-width: 767px) {
    margin: 7.5rem 0 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  margin-bottom: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1.4375rem;
  }
`

const Slider = styled.div`
  display: flex;
  height: 82.2vh;
  max-height: 46.25rem;
  overflow: hidden;
  outline: none;

  @media (max-width: 767px) {
    flex-direction: column;
    overflow: visible;
    height: auto;
    max-height: none;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 11.944vw;

  @media (max-width: 1023px) {
    right: 8.944vw;
  }

  @media (max-width: 991px) {
    right: 2rem;
  }
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Hand } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  URL?: string
}

export const FlashNews = memo(function FlashNews({ URL }: Props) {
  if (!URL) {
    return null
  }

  const buttonDOM = (
    <Label dial={5}>
      <Hand />
    </Label>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <Container>
      {externalURL ? (
        <ExtLink href={URL} rel="noopener" target="_blank">
          {buttonDOM}
        </ExtLink>
      ) : (
        <Anchor to={URL}>{buttonDOM}</Anchor>
      )}
    </Container>
  )
})

const Container = styled.aside`
  min-width: 6.125rem;
  position: absolute;
  top: calc(39.9vh - 1.875rem);
  left: 4.167vw;
  z-index: 4;

  @media (max-width: 1023px) {
    top: auto;
    left: auto;
    right: 1.9375rem;
    bottom: 9.125rem;
    min-width: auto;
  }
`

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  height: 4.375rem;
  background: ${theme.colors.variants.dangerLight};
  border-radius: 50%;
  color: ${theme.colors.variants.neutralLight4};
  cursor: pointer;
  margin: auto;
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const Label = styled(FlexBox)`
  height: 100%;
  position: relative;

  svg {
    width: auto;
    height: 2.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: auto;
    stroke: none;
  }
`

import styled from '@emotion/styled'
import { FlashNews } from 'app/components/FlashNews'
import { GiftBox } from 'app/components/GiftBox'
import { Hero } from 'app/components/Hero'
import { Hotels } from 'app/components/Hotels'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import { ServicesSlider } from 'app/components/ServicesSlider'
import { SimpleBox } from 'app/components/SimpleBox'
import { Spotify } from 'app/components/Spotify'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          stickerProps={context.stickerProps ? context.stickerProps : null}
          {...context.headerProps}
        />
      ) : null}
      {context.flashNewsProps ? (
        <FlashNews {...context.flashNewsProps} />
      ) : null}
      {context.spotifyProps ? <Spotify {...context.spotifyProps} /> : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="simple" {...context.introProps} />
      ) : null}
      {context.hotelsProps ? <Hotels {...context.hotelsProps} /> : null}
      {context.accomodationsProps ? (
        <Hotels {...context.accomodationsProps} />
      ) : null}
      {context.servicesSliderProps ? (
        <ServicesSlider {...context.servicesSliderProps} />
      ) : null}
      {context.simpleBoxProps ? (
        <SimpleBox {...context.simpleBoxProps} />
      ) : null}
      {context.giftBoxProps ? <GiftBox {...context.giftBoxProps} /> : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  title?: string
}

export const GiftBox = memo(function GiftBox({
  cta,
  description,
  image,
  title,
}: Props) {
  if (!description && !title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper row stretch wrap>
          <LeftSide>
            <Media greaterThanOrEqual="tabletMedium">
              {image ? (
                <LazyLoadComponent>
                  <svg width="0" height="0">
                    <defs>
                      <clipPath id="gift-path">
                        <path d="M179.259,459.267V132.521H326.751V459.267ZM0,459.267V132.521H147.434V459.267Zm52.113-363.8a47.242,47.242,0,0,1-41.3-23.927,47.26,47.26,0,0,1,.07-47.738A47.247,47.247,0,0,1,52.179,0h.066A47.258,47.258,0,0,1,93.558,23.932l41.23,71.659Zm183.3-71.544A47.245,47.245,0,0,1,276.706,0h.083a47.242,47.242,0,0,1,41.3,23.806,47.244,47.244,0,0,1,.072,47.732,47.245,47.245,0,0,1-41.313,23.927l-82.674.121Z" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Image {...image} />
                </LazyLoadComponent>
              ) : null}
            </Media>

            <Media between={['tabletSmall', 'tabletMedium']}>
              {image ? (
                <LazyLoadComponent>
                  <svg width="0" height="0">
                    <defs>
                      <clipPath id="gift-path">
                        <path d="M89.777,230.012V66.37h73.868V230.012ZM0,230.012V66.37H73.839V230.012Zm26.1-182.2A23.66,23.66,0,0,1,5.415,35.831,23.669,23.669,0,0,1,5.45,11.923,23.662,23.662,0,0,1,26.133,0h.033a23.668,23.668,0,0,1,20.69,11.986L67.505,47.874Zm91.8-35.831A23.661,23.661,0,0,1,138.581,0h.041a23.66,23.66,0,0,1,20.684,11.923,23.661,23.661,0,0,1,.036,23.905,23.661,23.661,0,0,1-20.69,11.983l-41.405.061Z" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Image {...image} />
                </LazyLoadComponent>
              ) : null}
            </Media>

            <Media lessThan="tabletSmall">
              {image ? (
                <LazyLoadComponent>
                  <svg width="0" height="0">
                    <defs>
                      <clipPath id="gift-path">
                        <path d="M56.205,144V41.551H102.45V144ZM0,144V41.551H46.227V144ZM16.34,29.934a14.812,14.812,0,0,1-12.949-7.5A14.818,14.818,0,0,1,3.412,7.464,14.814,14.814,0,0,1,16.36,0h.021A14.817,14.817,0,0,1,29.335,7.5L42.262,29.972ZM73.811,7.5A14.813,14.813,0,0,1,86.759,0h.026A14.812,14.812,0,0,1,99.735,7.464a14.813,14.813,0,0,1,.022,14.966,14.813,14.813,0,0,1-12.953,7.5l-25.922.038Z" />
                      </clipPath>
                    </defs>
                  </svg>

                  <Image {...image} />
                </LazyLoadComponent>
              ) : null}
            </Media>
          </LeftSide>

          <RightSide dial={4}>
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta ? <CTA variant="outline" {...cta} /> : null}
          </RightSide>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.75rem 0 12.5rem;
  padding: 0 11.944vw;

  @media (max-width: 1023px) {
    padding: 0 2rem;
  }

  @media (max-width: 991px) {
    margin: 10rem 0 6.25rem;
  }

  @media (max-width: 574px) {
    margin: 7.5rem 0 6.25rem;
  }
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 0 9.097vw 2.375rem 6.25vw;
  transform: translateY(-3.25rem);

  img {
    width: auto;
    height: 28.75rem;
    clip-path: url(#gift-path);
    -webkit-clip-path: url(#gift-path);
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  padding: 5.625rem 7.778vw 5.625rem 0;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 4rem 2.6875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 0.875rem;

  @media (max-width: 991px) {
    max-width: 37.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.6875rem;
`
